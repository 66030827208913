import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyBiPNAigXejoqpX_ovSJazvHxB2ByOPbj4",
  authDomain: "poogatype.firebaseapp.com",
  projectId: "poogatype",
  storageBucket: "poogatype.appspot.com",
  messagingSenderId: "239111725720",
  appId: "1:239111725720:web:902afb850e4fe71927e6ed",
  measurementId: "G-J5JKGKQVKG"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()

export const db = getFirestore(app)